const size = {
  mobile: '600px',
  tablet: '900px',
  desktop: '1200px',
}

const theme = {
  primary: '#3A6DF1',
  red:'#EB5757',
  yellow :'#F2C94C',
  green:'#6FCF97',
  //gray2: '#',
  gray3: '#828282',
  gray4: '#BDBDBD',
  gray5: '#E0E0E0',
  mobile: `(max-width: ${size.mobile})`,
  tablet: `(max-width: ${size.tablet})`,
  laptop: `(max-width: ${size.laptop})`,
  desktop: `(min-width: ${size.desktop})`,
}

export default theme