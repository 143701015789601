import { createGlobalStyle } from 'styled-components'
const GlobalStyle = createGlobalStyle`
  /** Medium */
  @font-face {
    font-family: "San Francisco";
    font-weight: 500;
    src: url("https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscodisplay-medium-webfont.woff");
  }

  /** Bold */ 
  @font-face {
    font-family: "San Francisco";
    font-weight: 700;
    src: url("https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscodisplay-bold-webfont.woff");
  } 
  * {
    margin: 0;
    padding: 0;  
  }
  /* CSS Document */
  * {-webkit-text-size-adjust:none;}
  * { margin:0; padding:0; border:0; -webkit-box-sizing: border-box;-moz-box-sizing: border-box;box-sizing: border-box;}
  article,aside,details,figcaption,figure, footer,header,hgroup,menu,nav,section, main {display:block;} /* delete vertical-align */
  html {}
  body {font-size:13px; overflow-x:hidden; color:#000; word-wrap:break-word; word-break:break-all; -webkit-text-size-adjust:none;  font-family:-apple-system, BlinkMacSystemFont,'San Francisco',"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif; line-height:1.4;} /**글자크기 자동조정 방지**/
  div, ul, form, fieldset, td, th, li, h1, h2, h3, h4, p { margin:0; padding:0; border:0;}
  ul, li, ol, dl { list-style:none;}
  form { display:inline;}
  address {font-style:normal;}

  em {font-style:normal;}

  a { color:inherit; text-decoration:none;}
  a:link { color:inherit; text-decoration:none;}
  a:visited { color:inherit; text-decoration:none;}

  ::selection {background-color: #ffa500;color: #fff;}

  input { vertical-align:middle;}
  select { vertical-align:middle; border:1px solid #ddd;}
  img, fieldset, iframe { border:0;}
  img {vertical-align:top; max-width:100%;}

  address, caption, cite, code, dfn, em, th, var { font-weight:500;}
  legend, caption {overflow:hidden; text-indent:-999px; width:100%; height:1px; font-size:0; line-height:0;}
  h1, h2, h3, h4, h5, h6 { font-weight:normal;}
  abbr, acronym { border:0; font-variant:normal;}
  del, ins {}

  .displayNone, hr {display:none;}
  table {border-collapse:collapse; border:0;}
  textarea {border:1px solid #ddd; line-height:140%; color:#606060;}
  button {border:0; padding:0; background:transparent; cursor:pointer; *overflow:visible; font-family:-apple-system, BlinkMacSystemFont, 'San Francisco',"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif; }

  html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,font,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,input,textarea,figure {
    margin:0;
    padding:0;
    border:0;	
    background:transparent;
    /**box-sizing:border-box;
    -moz-box-sizing:border-box;
    -webkit-box-sizing:border-box;**/ 
  }
  /* input */
  input[type="text"], input[type="password"], input[type="search"], input[type="number"], input[type="date"], input[type="datetime"], input[type="email"], input[type="tel"] {height:40px; margin:0; padding:0 10px; border:1px solid #dddddd; font-size:13px; color:#333333; background:#fff;}
  input[type="file"] {height:40px; vertical-align: middle;}
  select {height:40px; padding:0px 0px 0px 10px; border:1px solid #dddddd; font-size:13px; color:#333333; vertical-align: middle;}
  textarea {padding:10px; border:1px solid #dddddd;  font-family:-apple-system, BlinkMacSystemFont, 'San Francisco',"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif; font-size:13px; color:#333333; background:#fff; vertical-align: middle;}
  button {border:0; border: none; outline: none; background: none; cursor: pointer;}
  input[type="text"], input[type="password"], input[type="search"], input[type="number"], input[type="date"], input[type="datetime"], input[type="email"], input[type="tel"], input[type="submit"], input[type="button"],button {
    -webkit-border-radius:0;
    border-radius: 0px; /* iOS 둥근모서리 제거 */
      -webkit-appearance: none; /* 네이티브 외형 감추기 */
      -moz-appearance: none;
      appearance: none;
  }
  select::-ms-expand {display: none;}
  select {height:40px; padding:3px 23px 3px 16px !important; border:1px solid #ddd; font-size:13px; color:#303030;}
  select{
    border-radius: 5px; /* iOS 둥근모서리 제거 */ 
      -webkit-appearance: none; /* 네이티브 외형 감추기 */
      -moz-appearance: none;
      appearance: none;
    background: #fff url('../images/select_arrow.png') 100% 50% no-repeat;
    background-size: 14px auto !important;
    background-position: center right 10px;
    color:#333;
  }
  select:focus {outline:none;}
  select.focus{
    border-radius: 5px; /* iOS 둥근모서리 제거 */ 
      -webkit-appearance: none; /* 네이티브 외형 감추기 */
      -moz-appearance: none;
      appearance: none;
    background: #fff url('../../../images/mobile/common/select_arrow_icon02.png') 100% 50% no-repeat;
    background-size: 14px auto !important;
    background-position: center right 10px;
    border:1px solid #000; 
    color:#000;
  }

`

export default GlobalStyle