import React from 'react';
import styled, { css } from 'styled-components';

const LottoBall = ({ children, className, ...props }) => {
  return (
    <StyledBall
      {...props}
      className={className}
    >
      {children}
    </StyledBall>
  )
}
const className = css`
  ${(props) =>
    props.className === "under-10" &&
    css`
    background-color: #F2C94C;
    color:#000;
  `}

  ${(props) =>
    props.className === "under-20" &&
    css`
    background-color: #3A6DF1;
  `}

  ${(props) =>
    props.className === "under-30" &&
    css`
    background-color: #EB5757;
  `}

  ${(props) =>
    props.className === "under-40" &&
    css`
    background-color: #BDBDBD;
  `}

  ${(props) =>
    props.className === "under-45" &&
    css`
    background-color: #6FCF97;
  `}
`
const StyledBall = styled.span`
  display:flex;
  justify-content: center;
  align-items: center;
  width:40px;
  height:40px;
  border-radius: 50%;
  color:#fff;
  ${className};
  font-weight: 700;
  font-size: 14px;
`;

export default LottoBall;