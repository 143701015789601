import React from 'react';
import styled from 'styled-components';

const Button = ({ children, ...props }) => {
  return (
    <StyledButton
      {...props}
    >
      {children}
    </StyledButton>
  )
}

const StyledButton = styled.div`
  display:flex;
  justify-content: center;
  align-items: center;
  width:100%;
  min-height: 60px;
  background: #3A6DF1;
  border-radius: 8px;
  font-weight: 700;
  font-size: 18px;
  line-height: 1.4;
  color:#fff;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    background: #2c5de5;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    transform: translateY(-2px);
  }
`;

export default Button;