import React, { Component } from 'react';
import styled from 'styled-components';
import Button from '../components/Button';
import ButtonBox from '../components/ButtonBox';
import Title from '../components/Title';

class SelectGameCount extends Component {
  constructor(props) {
    super(props);

    this.state = {
      gameCount: props.gameCount
    }
  }

  changeGameCount(e) {
    let gameCount = e.target.value;
    if (gameCount > 50) {
      gameCount = 50;
    }

    this.setState({
      gameCount: parseInt(gameCount)
    });
  }

  confirm() {
    const { nextStep } = this.props;
    const { gameCount } = this.state;

    nextStep(gameCount)
  }

  render() {

    const { gameCount } = this.state;

    return (
      <div>
        <Title align="left">게임 생성</Title>
        <Desc>
          <p>선택 된 조건으로 생성하고 싶은<br />게임의 수를 선택해주세요.</p>
          {/* <p className="notice">- 최대 생성 열(row) : ??개</p> */}
        </Desc>
        <GameIntro>
          <Title size="medium" align="left" className="game__title">몇개의 게임을 생성하시나요?</Title>
          <Input value={gameCount} onChange={(e) => this.changeGameCount(e)}></Input> 개
        </GameIntro>

        {/* <div className="" style={{ padding: '80px 0', margin: ' 30px 0', textAlign: 'center', backgroundColor: '#828282' }}>--광고영역--</div> */}
        
        <ButtonBox>
          <Button onClick={() => this.confirm()}>다음으로</Button>
        </ButtonBox>
      </div>
    );
  }
}

const Desc = styled.div`
  & > p {
    font-size:12px;
    color:${(props) => props.theme.gray3};
    font-weight:500;
    &.notice {
      color:${(props) => props.theme.primary};
    }
  }
`;
const Input = styled.input.attrs({ type: 'number', placeholder: "숫자만 입력이 가능합니다.", min: "1", max: "50" })`
  width:100%;
  max-width:160px;
  padding:12px 15px !important;
  font-size:12px !important;
  font-weight:500 !important;
  margin-right: 4px !important;
  color:${(props) => props.theme.gray4} !important;
  border: 1px solid #F2F2F2 !important;
  border-radius: 8px !important;
  outline:none;
  &:focus {
    border:1px solid ${(props) => props.theme.primary} !important;
  }
`;

const GameIntro = styled.div`
  font-size: 14px;
  font-weight: 700;
  color:${(props) => props.theme.gray1};
  & > .game__title {
    margin-top:40px;
    margin-bottom: 8px;
  }
`;

export default SelectGameCount;