import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from '../components/Button';
import ButtonBox from '../components/ButtonBox';
import Title from '../components/Title';
import ChoiceDigitMain from "../lottoGenerator/choiceDigit/ChoiceDigitMain";
import IncludeNumberMain from "../lottoGenerator/includeNumber/IncludeNumberMain";
import ExcludeNumberMain from "../lottoGenerator/excludeNumber/ExcludeNumberMain";
import GoogleAdvertise from "../components/GoogleAdvertise";

const ChoiceLogic = {
  None: 'None',
  ChoiceDigit: 'ChoiceLogic',
  ExcludeNumber: 'ExcludeNumber',
  IncludeNumber: 'IncludeNumber'
}

const LottoChoiceTemplate = props => {

  const [state, setState] = useState({
    choiceLogic: ChoiceLogic.None
  })

  const onChoiceClick = (choice) => {
    setState({
      choiceLogic: choice
    })
  }

  const goMain = () => {
    setState({
      choiceLogic: ChoiceLogic.None
    })
  }

  const basicRender = () => {
    return (
      <>
        <Title>👇 로또 번호 생성하기 👇</Title>
        <ChoiceButton onClick={() => onChoiceClick(ChoiceLogic.ChoiceDigit)}>자리수 n개 설정하여 게임 생성</ChoiceButton>
        <ChoiceButton onClick={() => onChoiceClick(ChoiceLogic.IncludeNumber)}>포함될 번호를 설정하여 게임 생성</ChoiceButton>
        <ChoiceButton onClick={() => onChoiceClick(ChoiceLogic.ExcludeNumber)}>제외될 번호를 설정하여 게임 생성</ChoiceButton>
        
        {/* <GoogleAdvertise
          client="ca-pub-4795153506039295"
          slot="6455333107"
          format="auto"
          responsive="true"
        /> */}

        {/* <div className="" style={{ padding: '80px 0', margin: ' 30px 0', textAlign: 'center', backgroundColor: '#828282' }}>--광고영역--</div> */}
        
        {/* <ButtonBox>
          <Button>선택완료</Button>
        </ButtonBox> */}
      </>
    )
  }

  const { choiceLogic } = state;

  switch (choiceLogic) {
    case ChoiceLogic.ChoiceDigit:
      return <ChoiceDigitMain goMain={goMain}></ChoiceDigitMain>
    case ChoiceLogic.IncludeNumber:
      return <IncludeNumberMain goMain={goMain}></IncludeNumberMain>
    case ChoiceLogic.ExcludeNumber:
      return <ExcludeNumberMain goMain={goMain}></ExcludeNumberMain>
    case ChoiceLogic.None:
    default:
      return basicRender();
  }
};

LottoChoiceTemplate.propTypes = {

};

const ChoiceButton = styled.button`
  display:block;
  width:100%;
  min-height:80px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left:16px;
  background: #FFFFFF;
  border: 1px solid #F2F2F2;
  box-shadow: 0px 10px 16px rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  font-weight: 700;
  font-size: 14px;
  line-height: 1.4;
  background-repeat:no-repeat;
  background-position:left center;
  &:not(:first-child){
    margin-top:16px;
  }
  &:nth-of-type(1){
    background-image:url('image/emoji/01.png');
  }
  &:nth-of-type(2){
    background-image:url('image/emoji/02.png');
  }
  &:nth-of-type(3){
    background-image:url('image/emoji/03.png');
  }

  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: #2c5de5;
    color:#fff;s
  }

  &:active {
    transform: translateY(2px);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  }
`

export default LottoChoiceTemplate;
