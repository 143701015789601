import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';
import Title from '../../components/Title';


const Digit = props => {
  // state 및 props 설정
  const [state, setState] = useState({ checked: 0 });
  const { digit, max, remain, changeRemain } = props

  // 라디오 선택 이벤트
  const changeDigit = (e) => {
    const nowChecked = parseInt(e.target.value)
    changeRemain(nowChecked - state.checked, digit);
    setState({
      checked: nowChecked
    });
  }

  // 라디오 셋팅
  const ids = Array.apply(null, new Array(max + 1)).map((c, idx) => { return idx });

  const { checked } = state;
  const radioNumbers = ids.map((value, idx) => {
    const key = "digit_" + digit + "_" + value.toString()

    return (
      <InputBox key={key}>
        <Input
          id={key}
          type="radio"
          name={"digit_" + digit}
          value={value}
          disabled={(idx > remain && value > (checked + remain)) ?? true}
          checked={value === checked ?? true}
          onChange={changeDigit}
        />
        <Label htmlFor={key}>
          {value === 0 ? '0개' : value + "개"}
        </Label>
      </InputBox>
    )
  })

  return (
    <div>
      <DigitTitle size="small" align="left">{digit}의 자리</DigitTitle>
      <InputWrapper>{radioNumbers}</InputWrapper>
    </div>
  );
};

Digit.propTypes = {
  digit: PropTypes.number,
  max: PropTypes.number,
  remain: PropTypes.number,
  changeRemain: PropTypes.func
};

const InputBox = styled.div`
  flex-basis:16.7%;
`

const InputWrapper = styled.div`
  display:flex;
  gap:8px;
  width:100%;
  justify-content: space-between;
  height: 40px;
`;
const Input = styled.input`
  display:none;
  &:checked + label {
    border:2px solid ${(props) => props.theme.primary};
    color:${(props) => props.theme.primary};
  }
  &:disabled + label {
    background: #F2F2F2;
    color: #BDBDBD;
  }
`;
const Label = styled.label`
  display:flex;
  justify-content: center;
  align-items:center;
  width:100%;
  height:100%;
  background: #FFFFFF;
  border: 1px solid #F2F2F2;
  font-size: 14px;
  font-weight: 700;
  box-shadow: 0px 10px 16px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
`;

const DigitTitle = styled(Title)`
  margin-top:16px;
  margin-bottom:8px;
`
export default Digit;