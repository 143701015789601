import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';
import Button from '../../components/Button';
import ButtonBox from '../../components/ButtonBox';
import NumberButtons from '../../components/NumberButtons';
import Title from '../../components/Title';

const ChoiceExcludeNum = props => {
  const { nextStep, gameCount } = props;
  const [state, setState] = useState({ nums: [] });


  const choiceNumber = (choiceNums) => {
    setState({ nums: choiceNums });
  }

  const getMaxChoiceNumber = () => {
    // 게임	필요숫자	제외가능
    //    1       6        39
    //    7       7        38
    //   28       8        37
    //   84       9        36
    //  210      10        35

    if (gameCount === 1) {
      return 39;
    } else if (gameCount <= 7) {
      return 38;
    } else if (gameCount <= 28) {
      return 37;
    } else if (gameCount <= 84) {
      return 36;
    } else if (gameCount <= 210) {
      return 35;
    } 

    // 더 많이 들어오는 케이스는 생략한다.
    return 10;
  }

  const { nums } = state;
  return (
    <div>
      <Title align="left">제외될 번호 선택</Title>

      <Desc>
        <p>게임생성 시 제외시킬 번호를 선택하시면, </p>
        <p>선택된 번호를 우선 제외 후, </p>
        <p>랜덤 번호가 조합된 게임이 생성됩니다.</p>
        <p>- 최대 선택 번호 : <SelectedNumText>{getMaxChoiceNumber() - nums.length}개</SelectedNumText></p>
        <p>- 제외될 번호 : <SelectedNumText>{ nums.join(', ') }</SelectedNumText></p>
      </Desc>

      <NumberButtons maxNumber={getMaxChoiceNumber()} choiceNumber={choiceNumber}></NumberButtons>
      <ButtonBox>
        <Button onClick={()=> nextStep(nums)}>게임 생성</Button>
      </ButtonBox>
    </div>
  );
};

ChoiceExcludeNum.propTypes = {
  nextStep: PropTypes.func,
  gameCount: PropTypes.number
};
const Desc = styled.div`
  & > p {
    font-size:12px;
    color:${(props) => props.theme.gray3};
    font-weight:500;
    line-height: 1.5;
    &.notice {
      color:${(props) => props.theme.primary};
    }
  }
`;

const SelectedNumText = styled.span`
  font-size:12px;
  color:${(props) => props.theme.red};
  font-weight:500;
}
`;
export default ChoiceExcludeNum;