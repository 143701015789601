import React from 'react';
import styled, { css } from 'styled-components';

const Title = ({ size = 'large', color, align = 'center', children, ...props }) => {
  return (
    <StyledTitle
      size={size}
      color={color}
      align={align}
      {...props}
    >
      {children}
    </StyledTitle>
  )
}

/* 폰트 크기 */
const fontSize = css`
  ${(props) =>
    props.size === "large" &&
    css`
    font-size: 22px;
  `}

  ${(props) =>
    props.size === "medium" &&
    css`
    font-size: 16px;
  `}

  ${(props) =>
    props.size === "small" &&
    css`
    font-size: 14px;
  `}
`

/* 텍스트 정렬 */
const textAlign = css`
  ${(props) =>
    props.align === "center" &&
    css`
    text-align: center;
  `}

  ${(props) =>
    props.align === "left" &&
    css`
    text-align: left;
  `}

  ${(props) =>
    props.align === "right" &&
    css`
    text-align: right;
  `}
`

const StyledTitle = styled.h2`
  ${textAlign};
  ${fontSize};
  color:${(props => !props.color ? '#000' : props.color)};
  font-weight: 700;
  margin-bottom:24px;
`;

export default Title;