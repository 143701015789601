import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';

const NumberButtons = props => {
  const { maxNumber, choiceNumber } = props;
  const [state, setState] = useState({ nums: [] });

  const removeItem = (arr, value) =>{
    var index = arr.indexOf(value)
    if (index > -1) {
      arr.splice(index, 1);
    }
    return arr;
  }

  const changeNum = (e) => {
    const value = parseInt(e.target.value)
    let { nums } = state;

    if (nums.indexOf(value) > -1) {
      nums = removeItem(nums, value);
    } else {
      nums.push(value);
    }

    nums.sort((a, b) => a - b);

    setState({ nums: nums });
    choiceNumber(nums);
  }

  const ids = Array.apply(null, new Array(45)).map((c, idx) => { return idx+1});

  const Buttons = ids.map((value, idx) => { 
    const { nums } = state;
    const key = "num_" + value.toString()
    const isIncluded = nums.indexOf(value) > -1
    const isDisabled = nums.length >= maxNumber

    return (      
      <Button 
        key={key}
        value={value}
        onClick={changeNum}
        disabled={isDisabled && !isIncluded}
        className={isIncluded ? 'included' : undefined }
        >
        {/* {value} */}
        {/* 디자인 표현을 못해서 아래껄로 구분할수 있게함 */}
        {value}
      </Button>
    )
  })

  return (
    <ButtonBox>
      {Buttons}
    </ButtonBox>
  );
};
const ButtonBox = styled.div`
  display:flex;
  flex-wrap:wrap;
  gap:16px;
  justify-content: flex-start;
  padding-top: 16px;
`;
const Button = styled.button`
  display:flex;
  justify-content: center;
  align-items:center;
  width:calc(16.6% - 14px);
  height:40px;
  background: #FFFFFF;
  border: 1px solid #F2F2F2;
  font-size: 14px;
  font-weight: 700;
  box-shadow: 0px 10px 16px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  &.included {
    border:1px solid #3A6DF1;
    color:#3A6DF1;
  } 
`;
NumberButtons.propTypes = {
  maxNumber: PropTypes.number,
  choiceNumber: PropTypes.func
};

export default NumberButtons;