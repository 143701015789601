import React from 'react';
import styled from 'styled-components';

const LottoBallBox = ({ children, ...props }) => {
  return (
    <StyledBallBox
      {...props}
    >
      {children}
    </StyledBallBox>
  )
}

const StyledBallBox = styled.div`
  display:flex;
  justify-content: center;
  gap:8px;
  align-items: center;
  margin-bottom: 16px;
`;

export default LottoBallBox;