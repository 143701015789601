import React, { Component } from "react";
import styled from 'styled-components';
import { Link } from 'react-router-dom';

class LottoMainTemplate extends Component {

  handleLogoClick = () => {
    this.props.goMain();
  }

  render() {
    const { children } = this.props;
    return (
      <Wrapper>
        <Header>
          <LogoLink onClick={this.handleLogoClick}>
            <Logo></Logo>
          </LogoLink>
          <LottoSiteBox>
            <LottoSiteText>1등 번호 조회</LottoSiteText>
            <LottoSiteLink href="https://dhlottery.co.kr/gameResult.do?method=byWin" target="_blank">동행복권 바로가기</LottoSiteLink>
          </LottoSiteBox>
        </Header>
        <ContentWrapper className="app-content">
          {children}
        </ContentWrapper>
        <Footer>ⓒ 2022. <UnderLineText>YuNeungHan guys</UnderLineText> Inc. All rights reserved.</Footer>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  max-width:600px;
  margin:0 auto;
  position: relative;
  min-height: 100vh;
  box-sizing: border-box;
  padding-bottom: 50px;
  background-color: #f5f5f5;
`;

const ContentWrapper = styled.div`
  padding:0 16px;
  padding-bottom: 50px;
`;

const Header = styled.header`
  display:flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #E0E0E0;
  padding: 4px  8px;
  max-width:600px;
  margin-bottom:34px;
`;

const LogoLink = styled(Link)`
  display: flex;
  align-items: center;
`;

const Logo = styled.h1`
  width:40px;
  height: 40px;
  background: url('image/common/home.svg') center no-repeat;
`;
const LottoSiteBox = styled.div`
  display:flex;
  justify-content: flex-start;
  align-items: center;
  gap:8px;
`;
const LottoSiteText = styled.p`
  font-weight: 700;
  font-size: 12px;
  color: #828282;
`;
const LottoSiteLink = styled.a`
  font-weight: 700;
  font-size: 12px;
  border-bottom:1px solid #000;
`;

const Footer = styled.footer`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f5f5f5;
`;

const UnderLineText = styled.span`
  color: #BDBDBD;
  font-size: 11px;
  font-weight: 500;
  line-height: 1.4; 
  text-decoration: underline;
`;


export default LottoMainTemplate;
