import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Button from '../../components/Button';
import ButtonBox from '../../components/ButtonBox';
import LoadingBox from '../../components/LoadingBox';
import LottoBall from '../../components/LottoBall';
import LottoBallBox from '../../components/LottoBallBox';

const GenerateExcludeNumbers = props => {
  const { gameCount, excludeNumbers } = props
  const [state, setState] = useState({
    step: 1
  })

  const myNumbers = [];

  const rand = (min, max) => {
    return Math.floor(Math.random() * (max - min)) + min;
  }

  const randNotInArry = (min, max, notInArry) => {
    // max는 제외하고 만들어줌
    let value = 0;
    do {
      value = rand(min, max);
    } while (notInArry.filter((v) => v === value).length !== 0)
    
    return value
  }

  const makeNumbers = () => {
    for (let i = 0; i < gameCount; i++) {
      const nowNumbers = [];

      if (nowNumbers.length < 6) {
        for(let j = nowNumbers.length - 6; j < 0; j++) {
          nowNumbers.push(randNotInArry(1, 46, [...nowNumbers, ...excludeNumbers]));
        }
      }

      nowNumbers.sort((a, b) => a - b);
      myNumbers.push(nowNumbers);
    }
  }

  const displayNumbers = () => {
    const { step } = state;
    switch(step) {
      case 1: 
        setTimeout(()=> {
          setState({
            step: 2
          });
        }, 1000);

        return (
          <LoadingBox />
        )
      case 2:
        makeNumbers();
        const numbers = myNumbers.map((number, index) => {
          const numsKey = "numbers_" + index;
          const numItem = number.map((num, i) => {
            const NumKey = "number_" + i;
            let className = '';
            if(num > 0 && num < 10){
              className = 'under-10'
            }else if (10 <= num && num < 20) {
              className = 'under-20'
            }else if (20 <= num && num < 30) {
              className = 'under-30'
            }else if (30 <= num && num < 40) {
              className = 'under-40'
            }else {
              className = 'under-45'
            }            
            return (
              <LottoBall key={NumKey} className={className}>
                {num}
              </LottoBall>
            )
          })
          return (
            <LottoBallBox key={numsKey}>
              {numItem}
            </LottoBallBox>
          )
        });

        return (
          <div>
            {numbers} 
          </div>
        )
    }
    return
  }

  return (
    <>
      {displayNumbers()} 
      <ButtonBox>
        <Button onClick={()=> props.nextStep()}>메인으로 가기</Button>
      </ButtonBox>
    </>
  );
};

GenerateExcludeNumbers.propTypes = {
  gameCount: PropTypes.number,
  excludeNumbers: PropTypes.array,
  nextStep: PropTypes.func
};

export default GenerateExcludeNumbers;