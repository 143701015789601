import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';
import Button from '../../components/Button';
import ButtonBox from '../../components/ButtonBox';
import Title from '../../components/Title';
import Digit from './Digit';

const ChoiceDigit = props => {
  const { nextStep } = props;
  const [state, setState] = useState({
    remain: 6,
  });

  const [statDigits, setDigits] = useState({
    digits: [
      { digit: 1, choice: 0 },
      { digit: 10, choice: 0 },
      { digit: 20, choice: 0 },
      { digit: 30, choice: 0 },
      { digit: 40, choice: 0 }
    ]
  });

  const changeRemain = (value, digit) => {
    const { digits } = statDigits
    digits.filter((d) => d.digit === digit)[0].choice = value;

    setState({
      remain: state.remain - value
    });
  }

  const generateNumber = () => {
    const { digits } = statDigits
    nextStep(digits);
  }

  const { remain } = state;
  return (
    <div>
      <Title align="left">자리 수 선택</Title>
      <Title align="left" size="medium">최대 선택 자리 수 : {remain}</Title>
      <DigitBox>
        <Digit digit={1} max={6} remain={remain} changeRemain={changeRemain}></Digit>
        <Digit digit={10} max={6} remain={remain} changeRemain={changeRemain}></Digit>
        <Digit digit={20} max={6} remain={remain} changeRemain={changeRemain}></Digit>
        <Digit digit={30} max={6} remain={remain} changeRemain={changeRemain}></Digit>
        <Digit digit={40} max={6} remain={remain} changeRemain={changeRemain}></Digit>
      </DigitBox>
      <ButtonBox>
        <Button onClick={() => generateNumber()}>번호 생성</Button>
      </ButtonBox>
    </div>
  );
};

ChoiceDigit.propTypes = {
  nextStep: PropTypes.func
};

const DigitBox = styled.div`
  width:100%;
`;
export default ChoiceDigit;