import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

const StyledButtonBox = styled.div`
display: flex;
flex-direction: column; /* Wrapper 컴포넌트의 자식 요소들을 세로 방향으로 배치합니다. */
justify-content: space-between; /* Wrapper 컴포넌트의 자식 요소들을 위아래로 균등하게 배치합니다. */
align-items: center;
padding-top: 50px;
padding-bottom: 10px;
box-sizing: border-box;
background-color: #f5f5f5;
`;

const ButtonBox = ({ children, ...props }) => {
  const [bottom, setBottom] = useState(0);

  useEffect(() => {
    const handleResize = () => {
      const footerHeight = document.querySelector('footer').offsetHeight;
      const windowHeight = window.innerHeight;
      setBottom(windowHeight - footerHeight - 150);
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <StyledButtonBox
      {...props}
      bottom={bottom}
    >
      {children}
    </StyledButtonBox>
  )
}

export default ButtonBox;