import React, { useState } from 'react';
import PropTypes from 'prop-types';
import SelectGameCount from '../../components/SelectGameCount';
import ChoiceDigit from './ChoiceDigit'
import GenerateDigitNumbers from './GenerateDigitNumbers'

const ChoiceDigitMain = props => {
  const { goMain } = props;
  const [state, setState] = useState({
    step: 1
  });

  const [stateGameValues, setGameValues] = useState({
    gameValues: {
      gameCount: 5,
      digits: []  
    }
  })

  const nextStep = (step) => {
    setState({
      step : step ?? state.step + 1
    });
  }

  const eventGameCount = (gameCount) => {
    const { gameValues } = stateGameValues;

    setGameValues({ 
      gameValues : {
        ...gameValues,
        gameCount : gameCount
      }
    });

    nextStep();
  }

  const eventChoiceDigit = (digit) => {
    const { gameValues } = stateGameValues;

    setGameValues({ 
      gameValues : {
        ...gameValues,
        digits : digit
      }
    });

    nextStep();
  }

  const eventEndGame = () => {
    nextStep(0);
    goMain();
  }

  const { step } = state
  const { gameValues } = stateGameValues;

  switch (step) {
      case 1:
      default:
        return <SelectGameCount gameCount={gameValues.gameCount} nextStep={eventGameCount}/>;
      case 2:
        return <ChoiceDigit nextStep={eventChoiceDigit}></ChoiceDigit>
      case 3:
        return <GenerateDigitNumbers gameCount={gameValues.gameCount} digits={gameValues.digits} nextStep={eventEndGame}></GenerateDigitNumbers>
    }
};

ChoiceDigitMain.propTypes = {
  goMain: PropTypes.func
};

export default ChoiceDigitMain;
