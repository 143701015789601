import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ChoiceExcludeNum from './ChoiceExcludeNum';
import SelectGameCount from '../../components/SelectGameCount';
import GenerateExcludeNumbers from './GenerateExcludeNumbers';

const ExcludeNumberMain = props => {

  const { goMain } = props;
  const [state, setState] = useState({
    step: 1
  });

  const [stateGameValues, setGameValues] = useState({
    gameValues: {
      gameCount: 5,
      digits: []  
    }
  })

  const nextStep = (step) => {
    setState({
      step : step ?? state.step + 1
    });
  }

  const eventGameCount = (gameCount) => {
    const { gameValues } = stateGameValues;

    setGameValues({ 
      gameValues : {
        ...gameValues,
        gameCount : gameCount
      }
    });

    nextStep();
  }

  const choiceExcludeNumbers = (numbers) => {
    const { gameValues } = stateGameValues;

    setGameValues({ 
      gameValues : {
        ...gameValues,
        numbers : numbers
      }
    });

    nextStep();
  }

  const eventEndGame = () => {
    nextStep(0);
    goMain();
  }

  const { step } = state
  const { gameValues } = stateGameValues;

  switch (step) {
    case 1:
    default:
      return <SelectGameCount gameCount={gameValues.gameCount} nextStep={eventGameCount}/>;
    case 2:
      return <ChoiceExcludeNum gameCount={gameValues.gameCount} nextStep={choiceExcludeNumbers}></ChoiceExcludeNum>
    case 3:
      return <GenerateExcludeNumbers gameCount={gameValues.gameCount} excludeNumbers={gameValues.numbers} nextStep={eventEndGame}></GenerateExcludeNumbers>
  }
};

ExcludeNumberMain.propTypes = {
  goMain: PropTypes.func
};

export default ExcludeNumberMain;