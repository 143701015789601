import React from 'react';
import styled, { keyframes } from 'styled-components';

const LoadingBox = ({ children, className, ...props }) => {
  return (
    <StyledBox
      {...props}
      className={className}
    >
      <img src="image/common/loading-img.png" alt="loading"/>
      <p>
        <span>열</span><span>심</span><span>히</span>  <span>게</span><span>임</span> <span>만</span><span>드</span><span>는</span> <span>중</span>...
      </p>
    </StyledBox>
  )
}

const textE = keyframes`
  0% {
    color:#828282;
  }

  20% {
    color:#828282;
  }

  70% {
  color:#3A6DF1;
  }

  100% {
    color:#828282;
  }
`
const StyledBox = styled.div`
  display:flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin:30px auto;
  width:100%;
  font-size: 18px;
  font-weight: 700;
  & span {
    color:#828282;
    opacity:1;
    font-size: 18px;
    font-weight: 700;
    animation:${textE} 1.5s infinite;
    &:nth-of-type(2){
      animation-delay: .05s;
    }
    &:nth-of-type(3){
      animation-delay: .1s;
    }
    &:nth-of-type(4){
      animation-delay: .15s;
    }
    &:nth-of-type(5){
      animation-delay: .2s;
    }
    &:nth-of-type(6){
      animation-delay: .25s;
    }
    &:nth-of-type(7){
      animation-delay: .3s;
    }
    &:nth-of-type(8){
      animation-delay: .35s;
    }
    &:nth-of-type(9){
      animation-delay: .4s;
    }
  }
`;

export default LoadingBox;