import React, { Component } from 'react';
import { ThemeProvider } from 'styled-components';
import GlobalStyle from './style/global';
import theme from './style/theme';
import LottoChoiceTemplate from './templates/LottoChoiceTemplate';
import LottoMainTemplate from './templates/LottoMainTemplate';

class App extends Component {
  render() {
    return (
      <>
        <GlobalStyle />
        <ThemeProvider theme={theme}>
          <LottoMainTemplate>
            <LottoChoiceTemplate> </LottoChoiceTemplate>
          </LottoMainTemplate>
        </ThemeProvider>
      </>
    );
  }
}

export default App;