import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';
import Button from '../../components/Button';
import ButtonBox from '../../components/ButtonBox';
import NumberButtons from '../../components/NumberButtons';
import Title from '../../components/Title';

const ChoiceIncludeNum = props => {
  const { nextStep, gameCount } = props;
  const [state, setState] = useState({ nums: [] });
  
  const choiceNumber = (choiceNums) => {
    setState({ nums: choiceNums });
  }

  const getMaxChoiceNumber = () => {
    return gameCount * 6 > 45 ? 45 : gameCount * 6;
  }

  const { nums } = state;
  return (
    <>
      <Title align="left">포함될 번호 선택</Title>

      <Desc>
        <p>게임생성 시 포함시킬 번호를 선택하시면, </p>
        <p>선택된 번호를 우선 포함 후, </p>
        <p>랜덤 번호가 조합된 게임이 생성됩니다.</p>
        <p>- 최대 선택 번호 : <SelectedNumText>{getMaxChoiceNumber() - nums.length}개</SelectedNumText></p>
        <p>- 포함될 번호 : <SelectedNumText>{ nums.join(', ') }</SelectedNumText></p>
      </Desc>
      
      <NumberButtons maxNumber={getMaxChoiceNumber()} choiceNumber={choiceNumber}></NumberButtons>
      <ButtonBox>
        <Button onClick={()=> nextStep(nums)}>게임 생성</Button>
      </ButtonBox>
    </>
  );
};

ChoiceIncludeNum.propTypes = {
  nextStep: PropTypes.func,
  gameCount: PropTypes.number
};

const Desc = styled.div`
  & > p {
    font-size:12px;
    color:${(props) => props.theme.gray3};
    font-weight:500;
    line-height: 1.5;
    &.notice {
      color:${(props) => props.theme.primary};
    }
  }
`;

const SelectedNumText = styled.span`
  font-size:12px;
  color:${(props) => props.theme.red};
  font-weight:500;
}
`;

export default ChoiceIncludeNum;